.progress-tooltip-text{
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.2em;
}
#progress{
    .progress-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        a, button{
            margin: 0.5em;            
        }
    }
    .course-score{
        display: flex;
        flex-direction: column;
        align-items: center;
        .score{
            font-size: 1.2em;
            span{
                color: rgb(1, 69, 172);
                font-weight: 800;
                font-size: 1.3em;
            }
        }

        .progress{
            font-size: 1.1em;
            font-weight: 600;
            span{
                color: green;
                font-weight: 800;
            }
        }
    }
}