@import "../../variables.scss";

#root {
    .courses-list{
        margin-bottom: 2em;
    }

    ul.course-experiments-list{
        font-size: 0.9em;        
        
        

        @media screen and (max-width: $bp_tablet) {
            column-count: 1;
        }        

        @media screen and (min-width: $bp_tablet) {
            column-count: 1;
        }

        @media screen and (min-width: $bp_desktop) {
            column-count: 2;
        }
        

        li{
            line-height: 1em;
            margin-bottom: 0.3em;            
        }
    }
}