@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import './variables.scss';

html,
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  flex-grow: 1;

  #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    h1 {
      font-size: 3em;
    }

    .navbar {
      // background: #5c7372;
      height: $headerHeight;
      padding: 0 2em;
      color: white;
      .menu-button {
        cursor: pointer;
      }
    }

    .main {
      flex-grow: 1;
      display: flex;
      justify-content: stretch;
      transition: 255ms padding-left;
      padding-top: #{$headerHeight};
      // padding-bottom: #{$footerHeight + $mainTopMargin};
      &.drawer-open {
        padding-left: $sidebarWidth;
      }
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: $footerHeight;
      align-items: center;
      width: 100%;
      justify-content: center;
      color: white;
      z-index: 1;
      // background: #7ea09f;
    }

    .breadcrumbs {
      padding: 10px;
      margin: 0 0 10px 10px;
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: 70px;
      align-items: center;
      width: 100%;
      justify-content: center;
      background: #7ea09f;
    }
  }
  .skeleton {
    padding: 1em;
    display: flex;
    flex-grow: 1;
    min-height: 3em;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: $borderRadius;
    margin: 1em 0;
  }
}


$flexItemsMethods: baseline, flex-start, flex-end, stretch, center;
$flexContentMethods: baseline, flex-start, flex-end, stretch, center, space-around, space-evenly, space-between;

.flex {
  display: flex !important;

  &.column{
    flex-direction: column;
  }
  &.row{
    flex-direction: row;
  }

  @each $var in $flexItemsMethods {
    &.align-items-#{$var}{
      align-items: $var;
    }  
  }

  @each $var in $flexContentMethods  {
    &.justify-content-#{$var}{
      justify-content: $var;
    }
  }
}

.grow-0{
  flex-grow: 0;
}

.grow-1{
  flex-grow: 1;
}

.grow-2{
  flex-grow: 1;
}

