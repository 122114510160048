@import "../../../variables.scss";
#root {  
  .app-bar-component{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    height: $headerHeight;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5 );

    .app-bar-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $pagePaddingX;
        flex-grow: 1;
    }

    .link{
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      color: $logoColor;
      cursor: pointer;
    }

    .menu-button{
      cursor: pointer;
    }
  
    
  }

  

  .center-logo{
    display: flex;
    width: 10em;
    padding: 0.2em 1em;
    background: $logoColor;
    border-radius: 0 0 $borderRadius $borderRadius;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5 );
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}