.fraction {
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0.4em;
}

.nom,
.denom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nom {
  border-bottom: 1px solid black;
}

sub {
  font-size: 0.7em;
  top: 0.6em;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 0.7em;
  position: relative;
  top: -0.4em;
  vertical-align: baseline;
}
