body #root .main {
  padding-bottom: 0px;
}

#root {
  .user-profile {
    //background-color: cadetblue;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    //justify-content: center;
    margin-top: 1.6em;
    max-height: 55vh;

    .details-container {
      border-radius: 8px;
      border: 2px solid black;
      align-content: space-around;
      display: flex;
      max-height: 35vh;
      padding: 1em;

      .details-text {
        margin-top: 25px;
      }
    }

    .image-container {
      //background-color: wheat;
      display: flex;
      max-height: 35vh;
      flex-direction: column;
      align-content: space-around;
      justify-content: space-between;

      .image {
        width: 50%;
        //border: 5px solid #636035;
        border: 2px solid black;
        border-radius: 8px;
      }
    }

    .status-credentials-container {
      border-radius: 8px;
      border: 2px solid black;
      align-content: flex-start;
      max-height: 35vh;
      padding: 1em;
      display: flex;

      .status {
        padding: 1em;
        border-bottom: 2px solid black;
        display: flex;
        flex-direction: row;
        max-height: 10vh;
        align-items: center;
        justify-content: space-around;
      }

      .credentials {
        padding: 1em;
        max-height: 28vh;

        display: flex;
        flex-direction: column;

        .list-text {
          margin-top: 25px;
        }
      }
    }

    .progress-container {
      border-radius: 8px;
      border: 2px solid black;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: center;

      flex-wrap: nowrap;
      padding: 1em;

      .accorion-item {
        width: 80%;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        #accordion-title {
          background-color: rgba(170, 177, 174, 0.432);
          display: flex;
        }
        #accordion-title > div {
          justify-content: space-between;
        }
      }

      .accorion-details {
        display: flex;
        flex-direction: column;

        .line {
          display: flex;
          flex-direction: row;

          justify-content: space-between;
          padding: 0 1em 0 1em;
        }
      }
    }

    .buttons-container {
      display: flex;

      justify-content: flex-end;
      align-items: center;
    }
  }
}

.password-modal-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 65%;
  width: 75% !important;
  min-height: 50vh;
  background-color: rgb(204, 196, 196);
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-around;

  .header-container {
    display: flex;
    justify-content: center;
    padding: 10px;

    .header {
      font-weight: bold !important;
      font-size: 2em !important;
      margin-bottom: 7px !important;
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .buttons-container {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }
}

ul.tooltip-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  li {
    list-style: none;
    padding: 0;
    margin: 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    span:first-child {
      width: 5em;
      margin-right: 2em;
      white-space: nowrap;
    }
    span:nth-child(2),
    span:nth-child(3) {
      width: 4em;
      white-space: nowrap;
    }
  }
}
