@import '../../variables.scss';

.wrapper-tabs {
  text-align: center;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 3em;
    margin-top: 1em;
    margin-bottom: 0.3em;
  }
}
#login,
#register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  background-image: url('./images/student-login-bg.jpg');
  background-position: center;
  background-size: cover;
}

#reset-password-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-position: center;
  background-size: cover;

  .reset-form {
    flex-grow: 0;
    text-align: center;
    padding: 2em;
    background: rgba(255, 255, 255, 0.5);
    max-width: 400px;
    border-radius: $borderRadius;
  }

  .buttons {
    button{
      margin: 0.5em 0.5em 2em;
    }
  }
}

#login > div,
#register > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  h1 {
    font-size: 3em;
    margin-top: 1em;
    margin-bottom: 0.3em;
    text-align: center;
  }

  .login-form {
    flex-grow: 0;
    text-align: center;
    padding: 2em;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 1em rgba(0,0,0,0.8);
    max-width: 400px;
    border-radius: $borderRadius;
  }

  .st-labs-title {
    position: relative;
    &::before {
      content: '';
      background:$primary;
      background-blend-mode: overlay;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
    }

    svg {
      z-index: 100;
    }

    display: flex;

    width: 200px;
    padding: 1em 2em;
    border-radius: 12px;
    overflow: hidden;
    margin-top: -27vh;
    margin-bottom: -61vh;
  }
}

.reset-password-link {
  color: rgba(0, 0, 255, 0.5);
}
