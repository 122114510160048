$rightColor: green;

.quiz-container {
  // background-color: #cfe8fc;
  background-color: #eff7fd;
  padding: 10px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  
  .question-container {
    padding: 10px;
  }

  .quiz-title {
    font-size: 1.3em;
    FONT-WEIGHT: 400;
    color: #3f51b5;
    margin-bottom: 1em;
  }
  
  .question-text {
    flex: 1;
    padding: 10px;
  }
  
  #question-title {
    font-weight: bold;
    color: #f50057;
    margin-bottom: 12px;
  }

  .answered-correctly{
    .question-text{
      color: rgba(0,0,0,0.5);
    }

    // water down all other options in correctly answered question
    svg{
     color: rgba(0,0,0,0.1);
    }
    .selected{
      // mark selected correct answer with color
      svg{
        color: $rightColor;
        ;
      }
      .question-text{
        color: $rightColor;
        font-weight: 700;
      }
    }
  }
  
  .image {
    width: 200px;
    height: auto;
    padding: 15px;
  
    .question-image {
      width: 300px;
    }
  }
  
  .option {
    display: flex;
    flex-direction: row;
  }
}
