@import "../../variables.scss";

#root {
  header{
    .top-right{
      
      .user-icon{
        position: relative;
      }
    }

    .top-left{
      
      a{
        text-decoration: none;
        color: $logoColor;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.2em;
        }
      }

      img{
        max-height: $headerHeight - $headerLogoOffset;        
      }

    }
  }
}