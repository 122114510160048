.multiple-choice-buttons{
  position: relative;

  display: inline-flex;

  border-radius: 6px;
  // border: 1px solid;
  padding: 2px 10px;
  flex-direction: column;

  // &.vertical{
  //   flex-direction: column;
  // }

  
  color: #afafaf;

  button {
    color: gray;

    &.selected {
      color: white;
    }

    &:disabled{
      background-color: #afafaf;          
      cursor: not-allowed;      
    }
  }


  &.correct, &.wrong {
    &::after{
      // content: attr(data-message);
      // padding: 2px;
      // position: relative;
      // // left: 50%;
      // // transform: translateX(-50%);
      // // bottom: 0px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // width: 100%;
      // font-size: 0.7em;
      // letter-spacing: 0.06em;
      // font-weight: 700;
      // text-shadow: 1px 1px 3px white;
    }    
  }

  

  &.correct {
    // color: #006b92;
    // background-color: #00b9ff33;    
    color: #009259;
    background-color: #00ff7333;
    .MuiButton-contained.selected:disabled{
      background-color: rgb(162, 192, 162);
    }
  }
  &.wrong {
    color: crimson;
    background-color: rgba(255, 0, 0, 0.31);

    .MuiButton-contained.selected{
      background-color: crimson;
      &:disabled{
        background-color: rgb(224, 171, 182);
      }
    }

    .MuiButton-contained.clicked{
      background-color: gray;
      color: white;
      text-shadow: 0 0 4px black;
    }
    
  }



  button{


    
    // reset border radius
    &.MuiButton-contained {
        border-radius: 0;
        flex-grow: 1;

        &.selected{
          background-color: #1a8b72;
        }
    }
    // set border radius on first and last buttons
    &.MuiButton-contained:first-child {
      border-radius: 6px 0 0 6px;
    }
    &.MuiButton-contained:last-child {
        border-radius: 0 6px 6px 0;
    }
  }

  .MuiButtonGroup-vertical{
    button{
      &.MuiButton-contained:first-child {
        border-radius: 6px 6px 0 0;
      }
      &.MuiButton-contained:last-child {
          border-radius: 0 0 6px 6px;
      }
    }
  }


  .description {
    font-weight: 600;
    // color: gray;
    margin-bottom: 4px;
  }

  .error-message{
    // padding: 0;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 0px;
    
      padding: 2px;
      position: relative;
      // left: 50%;
      // transform: translateX(-50%);
      // bottom: 0px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      display: block;
      text-align: center;
      width: 100%;
      font-size: 0.7em;
      letter-spacing: 0.06em;
      font-weight: 700;
      text-shadow: 1px 1px 3px white;
  }

}