.exp-list-container {
  //the accordion always in viewport
  position: sticky;
  top: 0;

  counter-reset: exp-counter;
  .accordion-list {
    .accordion-experiment-title::before {
      counter-increment: exp-counter;
      content: counter(exp-counter);
      background: gray;
      border-radius: 50%;
      width: 2em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0.5em;
      color: white;
    }
  }
}
.exp-content-container {
  //background-color: rgb(231, 231, 220);
}

.error-message {
  text-align: center;
  padding: 60px;
}

.MuiAccordionSummary-content > p {
  padding-left: 2em;
}
